import React from "react"
import { Link } from "gatsby"
import { RiArrowLeftSLine, RiCheckboxCircleLine } from "react-icons/ri"

import SEO from "../components/seo"
import Layout from "../components/layout"
import { FlexBlock, Spacer, WrapperNarrow } from "../styles/layout"
import { Heading3, Paragraph } from "../styles/typography"

const Thanks = () => (
  <Layout className="thanks-page">
    <SEO title="Thank you" description="Thanks for sending us a message.  We will get back to you soon." />
    <Spacer height={10} />
    <WrapperNarrow>
      <FlexBlock direction="column">

        <RiCheckboxCircleLine style={{
          fontSize: "128px",
          color: "var(--primary-color)"
        }} />
        <Heading3>Got your message</Heading3>
        <Paragraph>Thank you for getting in touch us. We will get back to you shortly.</Paragraph>
        <Link to="/" className="button"><RiArrowLeftSLine className="icon -left" />Lets go back to Homepage</Link>

      </FlexBlock>

    </WrapperNarrow>

  </Layout>
)

export default Thanks